<template>
  <fieldset
    class="@container border-b border-b-gray-200 py-4 first:pt-0 last:border-b-0 last:pb-0"
  >
    <div
      :class="{
        'block @xl:flex w-full space-y-2 @xl:space-y-0 @xl:space-x-4 justify-between': true,
        // If it has a toggle, center-align it vertically.
        'has-[button[role=switch]]:items-center': true,
        // ...or a Switcher...
        'has-[[type=radio][role=switch]]:items-center': true,
        // Or if it has a color input.
        'has-[input[type=color]]:items-center': true,
        'items-center': !$slots.description,
        // If it's got an icon, we always flex and just hide the description.
        '!flex !space-x-4 !space-y-0 items-center': $slots.icon,
      }"
    >
      <div v-if="$slots.icon">
        <slot name="icon"></slot>
      </div>
      <div class="@xl:w-1/2 flex-1">
        <div
          v-if="$slots.header || $slots.validator"
          class="flex justify-between"
        >
          <SettingHeader
            v-if="$slots.header"
            :id="settingId"
            :legend="type === 'group'"
          >
            <slot name="header"></slot>
          </SettingHeader>
          <SettingValidator v-if="$slots.validator">
            <slot name="validator"></slot>
          </SettingValidator>
        </div>
        <div
          v-if="$slots.description"
          :class="{
            'hidden @xl:block': $slots.icon,
          }"
        >
          <SettingDescription>
            <slot name="description"></slot>
          </SettingDescription>
        </div>
      </div>
      <div
        :class="{
          // All of these rules boil down to: does this setting have a button or other element that should be aligned to the right?
          'flex flex-1 flex-col @xl:items-end @xl:text-right': true,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import * as Sentry from "@sentry/vue";
import { onMounted, provide, useId } from "vue";

import { SETTING_CONTROL_KEY } from "./context";
import SettingDescription from "./SettingDescription.vue";
import SettingHeader from "./SettingHeader.vue";
import SettingValidator from "./SettingValidator.vue";

const props = withDefaults(
  defineProps<{
    type?: "single" | "group";
  }>(),
  {
    type: "single",
  }
);

// We don't want this to change.
const type = props.type;

const settingId = useId()!;
let registeredControls = 0;
let controlHasMultipleInputs = false;

provide(SETTING_CONTROL_KEY, {
  register({ multiple = false } = {}) {
    registeredControls++;
    controlHasMultipleInputs ||= multiple;

    if (type === "single") {
      return settingId;
    }
  },
});

// TODO: Later on, we should look into moving this as a dev-only error.
const err = (msg: string) => {
  console.error(msg);
  Sentry.captureMessage(msg, "error");
};

onMounted(() => {
  // We can only warn for `single` here because `group` could include
  // conditional inputs and that's something we can't catch.
  if (type !== "single") {
    return;
  }

  if (registeredControls === 0) {
    err(`<Setting>: no controls mounted`);
  }

  if (registeredControls > 1 || controlHasMultipleInputs) {
    err(
      `<Setting>: multiple controls are mounted despite 'type' being set to 'single'`
    );
  }
});
</script>
